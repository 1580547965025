import { useId } from 'react-aria';
import { defaultIconProps, IconProps } from './IconProps';

export const IconTableCopy: import('react').FC<IconProps> = ({ size, color, ...rest }) => {
  const randomId = useId();

  return (
    <div style={{ display: 'grid', placeContent: 'center', minWidth: size, minHeight: size }}>
      <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
        <g clipPath={`url(#icon-table-copy-${randomId})`}>
          <path
            d="M6.6665 6.66666V4.33332C6.6665 3.3999 6.6665 2.93319 6.84816 2.57667C7.00795 2.26307 7.26292 2.0081 7.57652 1.84831C7.93304 1.66666 8.39975 1.66666 9.33317 1.66666H15.6665C16.5999 1.66666 17.0666 1.66666 17.4232 1.84831C17.7368 2.0081 17.9917 2.26307 18.1515 2.57667C18.3332 2.93319 18.3332 3.3999 18.3332 4.33332V10.6667C18.3332 11.6001 18.3332 12.0668 18.1515 12.4233C17.9917 12.7369 17.7368 12.9919 17.4232 13.1517C17.0666 13.3333 16.5999 13.3333 15.6665 13.3333H13.3332M4.33317 18.3333H10.6665C11.5999 18.3333 12.0666 18.3333 12.4232 18.1517C12.7368 17.9919 12.9917 17.7369 13.1515 17.4233C13.3332 17.0668 13.3332 16.6001 13.3332 15.6667V9.33332C13.3332 8.3999 13.3332 7.93319 13.1515 7.57667C12.9917 7.26307 12.7368 7.0081 12.4232 6.84831C12.0666 6.66666 11.5999 6.66666 10.6665 6.66666H4.33317C3.39975 6.66666 2.93304 6.66666 2.57652 6.84831C2.26292 7.0081 2.00795 7.26307 1.84816 7.57667C1.6665 7.93319 1.6665 8.3999 1.6665 9.33332V15.6667C1.6665 16.6001 1.6665 17.0668 1.84816 17.4233C2.00795 17.7369 2.26292 17.9919 2.57652 18.1517C2.93304 18.3333 3.39975 18.3333 4.33317 18.3333Z"
            stroke={color}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id={`icon-table-copy-${randomId}`}>
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

IconTableCopy.defaultProps = defaultIconProps;
