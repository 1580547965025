export type eventT = KeyboardEvent;
// Extend the Window interface to include keyboardShortcutsMap
declare global {
  interface Window {
    keyboardShortcutsMap?: Array<{ shortcut: string; functionName: string; definition: string }>;
  }
}

// Define the context type
export type ShortcutContextType = {
  registerListener: (shortcutKey: Set<string>, eventHandler: (event: eventT) => void) => void;
  unregisterListener: (shortcutKey: Set<string>) => void;
  shortcutMap: Map<string, (event: eventT) => void>;
  pressedKeys: KeysT;
  triggerKeyDown: (event: unknown) => void;
};

// Define the keyboard keys type
export type KeysT = string | undefined;

// Define special keys
export enum SpecialKey {
  Shift = 'shift',
  Ctrl = 'control',
  Alt = 'alt',
  Meta = 'meta',
}
