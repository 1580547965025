import { defaultIconProps, IconProps } from './IconProps';

export const IconUser2: React.FC<IconProps> = ({ size = defaultIconProps.size, color = defaultIconProps.color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 11 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M5.49977 6.25C4.17891 6.25 3.00427 6.88776 2.25644 7.87749C2.09549 8.0905 2.01501 8.19701 2.01764 8.34095C2.01967 8.45216 2.08951 8.59246 2.17701 8.66112C2.29027 8.75 2.44722 8.75 2.76112 8.75H8.23843C8.55233 8.75 8.70928 8.75 8.82254 8.66112C8.91004 8.59246 8.97988 8.45216 8.98191 8.34095C8.98454 8.19701 8.90406 8.0905 8.74311 7.87749C7.99528 6.88776 6.82064 6.25 5.49977 6.25Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.49977 5C6.53531 5 7.37477 4.16053 7.37477 3.125C7.37477 2.08947 6.53531 1.25 5.49977 1.25C4.46424 1.25 3.62478 2.08947 3.62478 3.125C3.62478 4.16053 4.46424 5 5.49977 5Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
