import { defaultIconProps, IconProps } from './IconProps';

export const IconEdit2: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M6.99998 12.3333H13M1 12.3333H2.11636C2.44248 12.3333 2.60554 12.3333 2.75899 12.2965C2.89504 12.2638 3.0251 12.21 3.1444 12.1369C3.27895 12.0544 3.39425 11.9391 3.62486 11.7085L12 3.33334C12.5523 2.78106 12.5523 1.88563 12 1.33334C11.4477 0.781057 10.5523 0.781058 10 1.33334L1.62484 9.7085C1.39424 9.9391 1.27894 10.0544 1.19648 10.189C1.12338 10.3083 1.0695 10.4383 1.03684 10.5744C1 10.7278 1 10.8909 1 11.217V12.3333Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconEdit2.defaultProps = defaultIconProps;
