import { defaultIconProps, IconProps } from './IconProps';

export const IconThinPolygon: React.FC<IconProps> = ({ size = defaultIconProps.size, color = defaultIconProps.color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M8.66634 3.9999L4.33422 4.86632C4.09222 4.91472 3.97123 4.93892 3.87269 4.99777C3.78558 5.04979 3.71149 5.12101 3.65607 5.206C3.59339 5.30214 3.56443 5.42209 3.50652 5.66199L1.33301 14.6666M1.33301 14.6666L10.3376 12.493C10.5775 12.4351 10.6974 12.4062 10.7936 12.3435C10.8786 12.2881 10.9498 12.214 11.0018 12.1269C11.0607 12.0283 11.0849 11.9073 11.1332 11.6654L11.9997 7.33323M1.33301 14.6666L6.39034 9.60923M13.9121 5.24565L10.7539 2.08748C10.4899 1.82347 10.3579 1.69146 10.2057 1.642C10.0718 1.5985 9.92756 1.5985 9.79366 1.642C9.64144 1.69146 9.50944 1.82347 9.24543 2.08748L8.75392 2.57899C8.48991 2.843 8.3579 2.975 8.30845 3.12722C8.26494 3.26112 8.26494 3.40535 8.30845 3.53924C8.3579 3.69146 8.48991 3.82347 8.75392 4.08748L11.9121 7.24565C12.1761 7.50966 12.3081 7.64167 12.4603 7.69113C12.5942 7.73463 12.7385 7.73463 12.8724 7.69113C13.0246 7.64167 13.1566 7.50966 13.4206 7.24565L13.9121 6.75415C14.1761 6.49014 14.3081 6.35813 14.3576 6.20591C14.4011 6.07201 14.4011 5.92778 14.3576 5.79389C14.3081 5.64167 14.1761 5.50966 13.9121 5.24565ZM7.33301 7.33323C8.06939 7.33323 8.66634 7.93019 8.66634 8.66657C8.66634 9.40295 8.06939 9.9999 7.33301 9.9999C6.59663 9.9999 5.99967 9.40295 5.99967 8.66657C5.99967 7.93019 6.59663 7.33323 7.33301 7.33323Z"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
