import React from 'react';
import { IconProps, defaultIconProps } from './IconProps';

export const IconMask: React.FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <circle cx="9" cy="9" r="8.17" stroke={color} strokeWidth="1.66" />
    <mask id="mask0_60_6708" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <circle cx="9" cy="9" r="8.17" fill="#D9D9D9" stroke={color} strokeWidth="1.66" />
    </mask>
    <g mask="url(#mask0_60_6708)">
      <circle cx="1" cy="9" r="8.17" fill={color} stroke={color} strokeWidth="1.66" />
    </g>
  </svg>
);

IconMask.defaultProps = defaultIconProps;
