import { defaultIconProps, IconProps } from './IconProps';

export const IconServer: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <g clipPath="url(#clip0_1500_5999)">
      <path
        d="M4.9974 5.00008H5.00573M4.9974 15.0001H5.00573M4.33073 8.33342H15.6641C16.5975 8.33342 17.0642 8.33341 17.4207 8.15176C17.7343 7.99197 17.9893 7.737 18.1491 7.4234C18.3307 7.06688 18.3307 6.60017 18.3307 5.66675V4.33341C18.3307 3.39999 18.3307 2.93328 18.1491 2.57676C17.9893 2.26316 17.7343 2.00819 17.4207 1.8484C17.0642 1.66675 16.5975 1.66675 15.6641 1.66675H4.33073C3.39731 1.66675 2.9306 1.66675 2.57408 1.8484C2.26047 2.00819 2.00551 2.26316 1.84572 2.57676C1.66406 2.93328 1.66406 3.39999 1.66406 4.33341V5.66675C1.66406 6.60017 1.66406 7.06688 1.84572 7.4234C2.00551 7.737 2.26047 7.99197 2.57408 8.15176C2.9306 8.33341 3.39731 8.33342 4.33073 8.33342ZM4.33073 18.3334H15.6641C16.5975 18.3334 17.0642 18.3334 17.4207 18.1518C17.7343 17.992 17.9893 17.737 18.1491 17.4234C18.3307 17.0669 18.3307 16.6002 18.3307 15.6667V14.3334C18.3307 13.4 18.3307 12.9333 18.1491 12.5768C17.9893 12.2632 17.7343 12.0082 17.4207 11.8484C17.0642 11.6667 16.5975 11.6667 15.6641 11.6667H4.33073C3.39731 11.6667 2.9306 11.6667 2.57408 11.8484C2.26047 12.0082 2.00551 12.2632 1.84572 12.5768C1.66406 12.9333 1.66406 13.4 1.66406 14.3334V15.6667C1.66406 16.6002 1.66406 17.0669 1.84572 17.4234C2.00551 17.737 2.26047 17.992 2.57408 18.1518C2.9306 18.3334 3.39731 18.3334 4.33073 18.3334Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1500_5999">
        <rect width="20" height="20" fill={color} />
      </clipPath>
    </defs>
  </svg>
);

IconServer.defaultProps = defaultIconProps;
