import React, { createContext, useEffect, useState, useContext } from 'react';
import { eventT, KeysT, ShortcutContextType } from 'modules/KeyboardShortcuts/types';
import { createKey, shouldSkipShortcut } from './utils';

// Create the keyboard shortcut context
export const KeyboardShortcutContext = createContext<ShortcutContextType>({
  registerListener: () => {},
  unregisterListener: () => {},
  shortcutMap: new Map(),
  pressedKeys: undefined,
  triggerKeyDown: () => {},
});

export const useKeyboardShortcutsContext = () => useContext(KeyboardShortcutContext);

// KeyboardShortcutProvider component
const KeyboardShortcutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // State for storing shortcut map and pressed keys
  const [shortcutMap, setShortcutMap] = useState<Map<string, (event: eventT) => void>>(new Map());
  const [pressedKeys, setPressedKeys] = useState<KeysT>();

  // Handle key down event
  const triggerKeyDown = (e: unknown) => {
    const event = e as eventT;
    const key = createKey(event);
    if (!key) return;

    triggerListeners(key, event);
    setPressedKeys(key);
  };

  // Handle key up event
  const handleKeyUp = () => {
    setPressedKeys(undefined);
  };

  // Register listener for a shortcut key
  const registerListener = (shortcutKey: Set<string>, eventHandler: (event: eventT) => void) => {
    const key = buildShortcutIndex(shortcutKey);
    setShortcutMap((prev) => new Map(prev.set(key, eventHandler)));
  };

  // Unregister listener for a shortcut key
  const unregisterListener = (shortcutKey: Set<string>) => {
    const key = buildShortcutIndex(shortcutKey);

    setShortcutMap((prev) => {
      const newMap = new Map(prev);
      newMap.delete(key);
      return newMap;
    });
  };

  /**
   * Triggers the appropriate listener for a given shortcut key if conditions are met.
   * Prevents shortcuts from firing when focused on input fields or textareas.
   *
   * @param shortcutKey - The shortcut key being triggered (e.g., 'Ctrl+S').
   * @param event - The keyboard event that triggered the shortcut.
   */
  const triggerListeners = (shortcutKey: string, event: eventT): void => {
    const targetElement = event.target as HTMLElement;

    // Skip shortcut execution for blocked elements.
    if (shouldSkipShortcut(targetElement)) return;

    // Retrieve and invoke the event handler for the shortcut key, if defined.
    const shortcutHandler = shortcutMap.get(shortcutKey);
    shortcutHandler?.(event);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && shortcutMap) {
      try {
        const shortcutContent = Array.from(shortcutMap.entries()).map(([shortcut, func]) => {
          return { shortcut: shortcut.replace(/\+/g, ' '), functionName: func.name, definition: func.toString() };
        });
        window.keyboardShortcutsMap = shortcutContent;
      } catch (e) {
        console.error('Error setting keyboardShortcutsMap', e);
      }
    }
  }, [shortcutMap]);

  // Add event listeners on component mount and remove on unmount
  useEffect(() => {
    window.addEventListener('keydown', triggerKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', triggerKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [pressedKeys, shortcutMap]);

  // Render the provider with the context value and children
  return (
    <KeyboardShortcutContext.Provider value={{ registerListener, unregisterListener, shortcutMap, pressedKeys, triggerKeyDown }}>
      {children}
    </KeyboardShortcutContext.Provider>
  );
};

// Create a unique index for a set of shortcut keys
const buildShortcutIndex = (shortcutKey: Set<string>): string => Array.from(shortcutKey).sort().join('+');

export default KeyboardShortcutProvider;
