import { eventT, SpecialKey } from 'modules/KeyboardShortcuts/types';

// Create a key string from a keyboard event
export const createKey = (event: eventT): string => {
  if (!event.key) return '';

  const key = event.key.toLocaleLowerCase();
  const keys = [event.key.toLocaleLowerCase()];

  if (Object.values(SpecialKey).includes(key as SpecialKey)) {
    return key;
  }

  if (event.shiftKey) {
    keys.push(SpecialKey.Shift);
  }
  if (event.ctrlKey) {
    keys.push(SpecialKey.Ctrl);
  }
  if (event.altKey) {
    keys.push(SpecialKey.Alt);
  }
  if (event.metaKey) {
    keys.push(SpecialKey.Meta);
  }

  return keys.sort().join('+');
};

export const createKeyMappings = () => {
  const numbers = [')', '!', '@', '#', '$', '%', '^', '&', '*', '('];

  /**
   * Create a mapping from number to symbol and symbol to number
   * numbersToSymbol -> { 0: ')', 1: '!', 2: '@', 3: ...... }
   * symbolsToNumber -> { ')': 0, '!': 1, '@': 2, ...... }
   */
  const numbersToSymbol = Object.fromEntries(numbers.map((symbol, number) => [number, symbol]));

  const symbolsToNumber = Object.fromEntries(numbers.map((symbol, number) => [symbol, number]));

  return { numbersToSymbol, symbolsToNumber };
};

export const { numbersToSymbol, symbolsToNumber } = createKeyMappings();

/**
 * Determines whether a given HTML element should block shortcut execution.
 * Skips elements like <input> or <textarea>, commonly used for text input.
 *
 * @param element - The target HTML element to check.
 * @returns {boolean} - True if shortcuts should be blocked, otherwise false.
 */
export const shouldSkipShortcut = (element: HTMLElement): boolean => {
  // For example, typing 'a' in an input field should not trigger the shortcut 'a'
  const blockedTags = ['INPUT', 'TEXTAREA', 'SELECT'];
  return blockedTags.includes(element.tagName);
};
