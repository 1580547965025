import { IconProps } from './IconProps';

export const IconInfo: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4372 3.13673C11.6011 2.9315 12.7941 2.95753 13.948 3.21334C15.1018 3.46914 16.194 3.94972 17.1622 4.62763C18.1303 5.30554 18.9555 6.1675 19.5905 7.1643C20.2256 8.1611 20.658 9.27322 20.8633 10.4372C21.0685 11.6011 21.0425 12.7941 20.7867 13.948C20.5309 15.1018 20.0503 16.194 19.3724 17.1622C18.6945 18.1303 17.8325 18.9555 16.8357 19.5905C15.8389 20.2256 14.7268 20.658 13.5628 20.8633C12.3989 21.0685 11.2059 21.0425 10.052 20.7867C8.89816 20.5309 7.80597 20.0503 6.83781 19.3724C5.86966 18.6945 5.04451 17.8325 4.40948 16.8357C3.77444 15.8389 3.34196 14.7268 3.13673 13.5628C2.9315 12.3989 2.95753 11.2059 3.21334 10.052C3.46914 8.89816 3.94972 7.80597 4.62763 6.83781C5.30554 5.86966 6.1675 5.04451 7.1643 4.40948C8.1611 3.77444 9.27322 3.34196 10.4372 3.13673ZM14.3808 1.26074C12.9705 0.948088 11.5125 0.916273 10.0899 1.16712C8.66727 1.41796 7.30802 1.94654 6.0897 2.7227C4.87139 3.49885 3.81788 4.50736 2.98933 5.69066C2.16077 6.87396 1.5734 8.20887 1.26074 9.61917C0.948088 11.0295 0.916273 12.4875 1.16711 13.9101C1.41796 15.3327 1.94654 16.692 2.7227 17.9103C3.49885 19.1286 4.50736 20.1821 5.69066 21.0107C6.87396 21.8392 8.20887 22.4266 9.61917 22.7393C11.0295 23.0519 12.4875 23.0837 13.9101 22.8329C15.3327 22.582 16.692 22.0535 17.9103 21.2773C19.1286 20.5012 20.1821 19.4926 21.0107 18.3093C21.8392 17.126 22.4266 15.7911 22.7393 14.3808C23.0519 12.9705 23.0837 11.5125 22.8329 10.0899C22.582 8.66727 22.0535 7.30802 21.2773 6.0897C20.5012 4.87139 19.4926 3.81788 18.3093 2.98933C17.126 2.16077 15.7911 1.5734 14.3808 1.26074Z"
      fill={color}
    />
    <path fillRule="evenodd" clipRule="evenodd" d="M13 11L13 19L11 19L11 11L13 11Z" fill={color} />
    <path fillRule="evenodd" clipRule="evenodd" d="M11 8L11 5L13 5L13 8L11 8Z" fill={color} />
  </svg>
);
