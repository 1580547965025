import { defaultIconProps, IconProps } from './IconProps';

export const IconChevronRight: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <div style={{ display: 'grid', placeContent: 'center', minWidth: size, minHeight: size }}>
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <path d="M9 18L15 12L9 6" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </div>
);

IconChevronRight.defaultProps = defaultIconProps;
