import { defaultIconProps, IconProps } from 'components/Icons/IconProps';

export const IconEyeOff2: import('react').FC<IconProps> = ({ size, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M7.16196 2.39488C7.4329 2.35482 7.7124 2.33333 8.00028 2.33333C11.4036 2.33333 13.6369 5.33656 14.3871 6.52455C14.4779 6.66833 14.5233 6.74023 14.5488 6.85112C14.5678 6.93439 14.5678 7.06578 14.5487 7.14905C14.5233 7.25993 14.4776 7.3323 14.3861 7.47705C14.1862 7.79343 13.8814 8.23807 13.4777 8.7203M4.48288 3.47669C3.0415 4.45447 2.06297 5.81292 1.61407 6.52352C1.52286 6.66791 1.47725 6.74011 1.45183 6.85099C1.43273 6.93426 1.43272 7.06563 1.45181 7.14891C1.47722 7.25979 1.52262 7.33168 1.61342 7.47545C2.36369 8.66344 4.59694 11.6667 8.00028 11.6667C9.37255 11.6667 10.5546 11.1784 11.5259 10.5177M2.00028 1L14.0003 13M6.58606 5.58579C6.22413 5.94772 6.00028 6.44772 6.00028 7C6.00028 8.10457 6.89571 9 8.00028 9C8.55256 9 9.05256 8.77614 9.41449 8.41421"
      stroke="#344054"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconEyeOff2.defaultProps = defaultIconProps;
