import { defaultIconProps, IconProps } from './IconProps';

export const IconDotsMenuVertical: import('react').FC<IconProps> = ({ size, color, ...rest }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M9.9974 10.8333C10.4576 10.8333 10.8307 10.4602 10.8307 9.99999C10.8307 9.53976 10.4576 9.16666 9.9974 9.16666C9.53716 9.16666 9.16406 9.53976 9.16406 9.99999C9.16406 10.4602 9.53716 10.8333 9.9974 10.8333Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9974 4.99999C10.4576 4.99999 10.8307 4.6269 10.8307 4.16666C10.8307 3.70642 10.4576 3.33333 9.9974 3.33333C9.53716 3.33333 9.16406 3.70642 9.16406 4.16666C9.16406 4.6269 9.53716 4.99999 9.9974 4.99999Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.9974 16.6667C10.4576 16.6667 10.8307 16.2936 10.8307 15.8333C10.8307 15.3731 10.4576 15 9.9974 15C9.53716 15 9.16406 15.3731 9.16406 15.8333C9.16406 16.2936 9.53716 16.6667 9.9974 16.6667Z"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconDotsMenuVertical.defaultProps = defaultIconProps;
